<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>

        <el-row class='table-search'>
          <el-col :span='24'>
            <el-form :inline='true' :model='searchCondition'>
              <el-form-item label=''>
                <el-date-picker
                  v-model='searchCondition.time_value'
                  type='daterange'
                  align='right'
                  unlink-panels
                  range-separator='至'
                  start-placeholder='开始日期'
                  end-placeholder='结束日期'
                  value-format='yyyy-MM-dd' style='width: 300px'>
                </el-date-picker>
              </el-form-item>

              <el-form-item label=''>
                <el-input v-model='searchCondition.nickname' placeholder='用户昵称' clearable></el-input>
              </el-form-item>
              <el-form-item label=''>
                <el-input v-model='searchCondition.real_ip' placeholder='登录IP' clearable></el-input>
              </el-form-item>
              <el-form-item label=''>
                <el-select v-model='searchCondition.login_method' clearable>
                  <el-option v-for='(option,index) in loginMethodOptions' :value='option.value' :label='option.label'
                             :key='index'></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type='primary' @click='handleQuery'>查询</el-button>
              </el-form-item>

            </el-form>
          </el-col>
        </el-row>
        <div class='default-table'>
          <!--    渲染表格-->
          <m-table :showIndex='true' :offset='offset' max-height='800' :tableData='dataList' :columns='columns'
                   :loading='loadingStatus' @sort-change='changeTableSort' />
          <!--    渲染分页-->
          <m-pagination :total.sync='total' :limit.sync='pagingData.page_size' :page.sync='pagingData.current_page'
                        @pagination='getList' />
        </div>
        <!--        图表-->
        <div class='chart-box'>
          <div>
            <el-link type='primary' icon='el-icon-refresh' @click='showChart'>登录方式饼图</el-link>
          </div>
          <div>
            <pie-chart :chart-option='chartOption'></pie-chart>
          </div>
        </div>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import { mapGetters } from 'vuex'
import UserLoginLog from '@/pages/user/components/UserLoginLog'
import PieChart from '@/pages/dashboard/PieChart'

export default {
  name: 'LoginLog',
  components: {
    PieChart,
    UserLoginLog,
    PageHeaderLayout,
    ApeTable

  },
  data() {
    return {
      loadingStatus: false,
      // 搜索条件
      searchCondition: { login_method: null, nickname: null, time_value: ['2022-06-01', '2022-06-10'] },
      columns: [
        // {
        //   title: 'ID',
        //   value: 'id',
        //   width: 80
        // },
        {
          title: '昵称',
          value: 'nickname',
          width: 120,
          sortable: 'custom'
        },
        {
          title: '登录IP',
          value: 'real_ip',
          width: 100,
          sortable: 'custom'
        },
        {
          title: '登录时间',
          value: 'login_at',
          width: 140
        },
        {
          title: '登录方式',
          value: 'login_method_alias',
          width: 100
        },
        {
          title: '登录有效期',
          value: 'login_expire_at',
          width: 140
        },
        {
          title: '设备',
          value: 'device',
          width: 100
        },
        {
          title: '浏览器',
          value: 'browser',
          width: 100
        },
        {
          title: '系统',
          value: 'platform',
          width: 100
        },
        {
          title: '退出时间',
          value: 'logout_at',
          width: 100
        },
        {
          title: '记录时间',
          value: 'created_at',
          width: 140
        },
        {
          title: '更新时间',
          value: 'created_at',
          width: 140
        }
      ],
      // 分页信息
      pagingData: {
        current_page: 1,
        page_size: 15
      },
      total: 0,
      // 分页的offset,序号列使用
      offset: 0,
      dataList: [],
      orderSort: { id: 'desc' },
      loginMethodOptions: [
        { label: '账号登录', value: 1 },
        { label: '扫码登录', value: 2 },
        { label: '授权登录', value: 3 }
      ],
      chartOption: {},
      option: {
        title: {
          text: '用户登录方式',
          subtext: '2023-12-01 ~ 2023-12-22',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: '登录方式',
            type: 'pie',
            radius: '50%',
            data: [
              { value: 1048, name: '账号登录' },
              { value: 735, name: '授权登录' },
              { value: 580, name: '扫码登录' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }

    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  methods: {
    handleQuery() {
      this.pagingData.current_page = 1
      this.getList()
      this.showChart()
    },
    async getList() {
      this.dataList = []
      this.loadingStatus = true
      let searchCondition = this.handleSearchCondition()
      // 合并
      Object.assign(searchCondition, this.pagingData, { order: this.orderSort })
      let { list, pages } = await this.$api.getUserLoginLog(searchCondition)
      this.dataList = list
      this.total = pages.total
      this.offset = pages.offset
      this.pagingData.current_page = pages.current_page
      this.pagingData.page_size = pages.page_size
      this.loadingStatus = false
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.nickname) {
        condition.nickname = this.searchCondition.nickname
      }
      if (this.searchCondition.login_method) {
        condition.login_method = this.searchCondition.login_method
      }
      if (this.searchCondition.real_ip) {
        condition.real_ip = this.searchCondition.real_ip
      }
      if (this.searchCondition.time_value) {
        condition.start_time = this.searchCondition.time_value[0]
        condition.end_time = this.searchCondition.time_value[1]
      }
      return condition
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getList()

    },
    async showChart() {
      //请求数据
      let searchCondition = this.handleSearchCondition()
      let { option } = await this.$api.getUserLoginLogChartData(searchCondition)
      this.chartOption = { ...this.option }
      //筛选条件
      this.chartOption.title.subtext = option.subtext
      this.chartOption.series[0].data = option.data || []
    }
  },
  created() {
    //默认时间
    let date = new Date()
    let currentDay = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    let monthFirstDay = `${date.getFullYear()}-${date.getMonth() + 1}-01`
    this.searchCondition.time_value = [monthFirstDay, currentDay]
    this.handleQuery()
  }
}
</script>

<style scoped>
.chart-box {
  width: 50%;

}
</style>